import { useCallback } from 'react';

import { getAtlasURL } from '../../common/utils/atlas-url';
import { useTenantDataController } from '../tenant-data';

export const useAtlasURL = (siteId: string) => {
	const { data: tenantData } = useTenantDataController(siteId);

	const atlasURL = useCallback(
		(path: string) => getAtlasURL(siteId, path, tenantData?.orgId),
		[siteId, tenantData?.orgId],
	);

	return {
		atlasURL,
	};
};

export const useCreateAgentAtlasURL = (siteId: string) => {
	const { atlasURL } = useAtlasURL(siteId);
	return atlasURL('/chat/agents/new');
};
