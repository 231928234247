import { FetchError } from '../../../../common/utils/fetch-error';

import {
	type GqlError,
	type GqlResponse,
	type GqlSuccessResponse,
	type GqlVariables,
} from './types';

const extractQueryName = (gqlQuery: string): string | null => {
	const operationMatch = gqlQuery.match(/(?:query|mutation)\s+(\w+)/);
	return operationMatch ? operationMatch[1] : null;
};

export const performGqlFetch = async <T, U>({
	gqlQuery,
	headerOptions,
	variables,
}: {
	gqlQuery: string;
	headerOptions?: Record<string, string>;
	variables?: GqlVariables<U>;
}): Promise<GqlSuccessResponse<T>> => {
	try {
		const response = await fetch('/gateway/api/graphql', {
			headers: {
				'Content-Type': 'application/json',
				...headerOptions,
			},
			method: 'POST',
			body: JSON.stringify({ query: gqlQuery, variables }),
		});

		const json: GqlResponse<T> = await response.json();

		if (json?.errors?.length) {
			await processGqlErrorResponse({ errors: json.errors, gqlQuery });
		}
		return json as GqlSuccessResponse<T>;
	} catch (err) {
		throw new FetchError('GraphQL query failed', { cause: err });
	}
};

type GraphqlApiError = {
	message: string;
	status_code: number;
	classification: string;
};

export class GqlApiError extends Error {
	errors: GraphqlApiError[];
	queryName: string | null;
	constructor(
		message: string,
		{ errors, queryName }: { errors: GraphqlApiError[]; queryName: string | null },
	) {
		super(message);
		this.name = 'GraphQLError';
		this.errors = errors;
		this.queryName = queryName;
	}
}

const processGqlErrorResponse = async ({
	errors,
	gqlQuery,
}: {
	errors: GqlError[];
	gqlQuery: string;
}) => {
	const errorsToThrow: GraphqlApiError[] = [];

	errors.forEach((error) => {
		errorsToThrow.push({
			message: error.message,
			status_code: error.extensions.statusCode,
			classification: error.extensions.classification,
		});
	});

	throw new GqlApiError('GraphQL API error', {
		errors: errorsToThrow,
		queryName: extractQueryName(gqlQuery),
	});
};
