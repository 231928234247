import React from 'react';

import { defineMessages, IntlShape } from 'react-intl-next';

import AddIcon from '@atlaskit/icon/core/add';
import AIAgentIcon from '@atlaskit/icon/core/ai-agent';

export type StudioAction = {
	id: string;
	title: string;
	description?: string;
	icon: React.ReactElement;
	onClick?: () => void;
	href?: string;
};
export const i18n = defineMessages({
	aiStudioCreateAgentAction: {
		id: 'ai-smart-button.ai-actions.rovo-agents.create-agent.text',
		defaultMessage: 'Create agent',
		description: 'The action to create a new agent in the AI Studio',
	},
	aiStudioBrowseAgentAction: {
		id: 'ai-smart-button.ai-actions.rovo-agents.create-agent.text',
		defaultMessage: 'Browse agent',
		description: 'The action to create a new agent in the AI Studio',
	},
});

export function getStudioActions({
	intl,
	createAgentUrl,
	handleBrowseAgent,
}: {
	intl: IntlShape;
	createAgentUrl: string;
	handleBrowseAgent: () => void;
}): StudioAction[] {
	return [
		{
			id: 'create-agent',
			title: intl.formatMessage(i18n.aiStudioCreateAgentAction),
			href: createAgentUrl,
			icon: <AddIcon label="" />,
		},
		{
			id: 'browse-agent',
			title: intl.formatMessage(i18n.aiStudioBrowseAgentAction),
			onClick: handleBrowseAgent,
			icon: <AIAgentIcon label="" />,
		},
	];
}
