import { useEffect, useState } from 'react';

export const navigateActions = (
	length: number,
	currentIndex: number | undefined,
	direction: 'ArrowUp' | 'ArrowDown',
): number | undefined => {
	// Got nothing to do
	if (length === 0) {
		return undefined;
	}

	if (direction === 'ArrowUp') {
		// If the index has been reset, navigate to the last item
		if (currentIndex === undefined) {
			return length - 1;
		}
		/**
		 * Visualisation of what the scenarios are covering
		 * - Left = out of bounds negative indexes
		 * - Right = out of bounds positive indexes
		 * - 0-9 = example of valid suggestion indexes
		 *       Array items
		 * -----[0123456789]++++++
		 * <----DCB------>A-->
				 Scenarios
		*/
		// A: Out of bounds
		if (currentIndex > length - 1) {
			return 0;
		}

		// B: Move up the list
		else if (currentIndex > 0) {
			return currentIndex - 1;
		}

		// C: First item loops back to end of list
		else if (currentIndex === 0) {
			return length - 1;
		}

		// D: Default to first item
		else {
			return 0;
		}
	} else if (direction === 'ArrowDown') {
		// If the index has been reset, navigate to the first item
		if (currentIndex === undefined) {
			return 0;
		}
		/**
		 * Visualisation of what the scenarios are covering
		 * - Left = out of bounds negative indexes
		 * - Right = out of bounds positive indexes
		 * - 0-9 = example of valid suggestion indexes
		 *       Array items
		 * -----[0123456789]++++++
		 * <----BC------->A-->
				 Scenarios
		*/
		// A: Last item or out of bounds
		if (currentIndex >= length - 1) {
			return 0;
		}

		// B: Default to first item
		else if (currentIndex < 0) {
			return 0;
		}

		// C: Move down the list
		else if (currentIndex < length - 1) {
			return currentIndex + 1;
		}
	} else {
		throw new Error('Unknown direction');
	}

	return 0;
};

export const useNavigateActions = (listLength: number) => {
	const [selectedIndex, setSelectedIndex] = useState<number | undefined>();

	useEffect(() => {
		setSelectedIndex(undefined);
	}, [listLength]);

	const handleArrowDown = () => {
		const newIndex = navigateActions(listLength, selectedIndex, 'ArrowDown');
		setSelectedIndex(newIndex);
	};

	const handleArrowUp = () => {
		const newIndex = navigateActions(listLength, selectedIndex, 'ArrowUp');

		setSelectedIndex(newIndex);
	};

	return {
		handleArrowDown,
		handleArrowUp,
		selectedIndex,
	};
};
