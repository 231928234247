import {
	ExperienceName,
	useAIMateExperienceTracker,
} from '@atlassian/conversation-assistant-instrumentation';
import { type ApiError, useQuery } from '@atlassian/conversation-assistant-service';

import { getCloudUrlFromId } from '../../services/cloud-url';

export const useTenantDataController = (siteId: string) => {
	const experience = useAIMateExperienceTracker();
	const { data, isFetching, error } = useQuery({
		queryKey: ['viewAgentTenantData', siteId],
		queryFn: async () => {
			experience.start({
				id: ExperienceName.FETCH_AGENT_TENANT_DATA,
				name: ExperienceName.FETCH_AGENT_TENANT_DATA,
			});
			const result = await getCloudUrlFromId(siteId);
			return result;
		},
		onSuccess: () => {
			experience.succeed({
				name: ExperienceName.FETCH_AGENT_TENANT_DATA,
			});
		},
		onError: (error: ApiError) => {
			experience.fail({
				name: ExperienceName.FETCH_AGENT_TENANT_DATA,
				error,
			});
		},
	});

	return { data, isFetching, error };
};
