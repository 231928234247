import { performHttpFetch } from '@atlassian/conversation-assistant-service';

type Me = {
	account_id?: string;
	email?: string;
	name?: string;
	picture?: string;
	account_status?: string;
	characteristics?: { not_mentionable?: boolean };
	last_updated?: string;
	nickname?: string;
	zoneinfo?: string;
	locale?: string;
	extended_profile?: {
		job_title?: string;
		department?: string;
		location?: string;
	};
	account_type?: string;
	email_verified?: boolean;
};

export const me = async (): Promise<Me> => {
	const response = await performHttpFetch({
		path: '/gateway/api/me',
		options: { method: 'GET' },
	});
	return response;
};
