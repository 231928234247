import React, { useEffect, useRef } from 'react';

import { defineMessages, MessageDescriptor, useIntl } from 'react-intl-next';

import { cssMap } from '@atlaskit/css';
import Lozenge from '@atlaskit/lozenge';
import { ButtonItem, LinkItem } from '@atlaskit/menu';
import { Box, Flex, Inline, Text } from '@atlaskit/primitives/compiled';
import { token } from '@atlaskit/tokens';
import type { AIActionStatus } from '@atlassian/ai-smart-button-api';

export const i18n = defineMessages({
	aiActionStatusBeta: {
		id: 'ai-smart-button.ai-actions.item.betaLozenge',
		defaultMessage: 'BETA',
		description: 'Lozenge text to indicate that the action is in beta stage',
	},
	aiActionStatusNew: {
		id: 'ai-smart-button.ai-actions.item.newLozenge',
		defaultMessage: 'NEW',
		description: 'Lozenge text to indicate that the action is new',
	},
});

const styles = cssMap({
	buttonContentStyles: {
		display: 'flex',
		alignItems: 'center',
		gap: token('space.150'),
		paddingInline: token('space.150'),
	},

	inlineItemStyles: {
		color: token('color.text.subtle'),
		gap: token('space.100'),
	},

	iconBoxStyles: {
		width: '32px',
		height: '32px',
		minWidth: '32px',
		borderStyle: 'solid',
		borderWidth: token('border.width'),
		borderColor: token('color.border'),
		borderRadius: token('border.radius'),
	},
});

const statusLozengeMessages: Record<AIActionStatus, MessageDescriptor> = {
	beta: i18n.aiActionStatusBeta,
	new: i18n.aiActionStatusNew,
};

const BaseActionItem = ({
	title,
	icon,
	status,
}: {
	title: string;
	icon: React.ReactElement;
	status?: AIActionStatus;
}) => {
	const { formatMessage } = useIntl();
	const statusText =
		(status &&
			(statusLozengeMessages[status] ? formatMessage(statusLozengeMessages[status]) : null)) ||
		status;
	return (
		<Box xcss={styles.buttonContentStyles}>
			<Flex justifyContent="center" alignItems="center" xcss={styles.iconBoxStyles}>
				{icon}
			</Flex>
			<Inline xcss={styles.inlineItemStyles} alignBlock="center">
				<Text>{title}</Text>
				{statusText && <Lozenge appearance="default">{statusText}</Lozenge>}
			</Inline>
		</Box>
	);
};

export function DynamicSurfaceActionItem({
	title,
	icon,
	isSelected,
	onClick,
	href,
	status,
}: {
	title: string;
	icon: React.ReactElement;
	isSelected: boolean;
	onClick?: () => void;
	href?: string;
	status?: AIActionStatus;
}) {
	const ref = useRef<HTMLElement>(null);
	useEffect(() => {
		if (isSelected && ref.current) {
			ref.current.scrollIntoView({
				behavior: 'instant',
				block: 'nearest',
			});
		}
	}, [isSelected, ref]);

	const item = <BaseActionItem title={title} icon={icon} status={status} />;

	if (href) {
		return (
			<LinkItem ref={ref} target="_blank" isSelected={isSelected} href={href}>
				{item}
			</LinkItem>
		);
	}
	return (
		<ButtonItem ref={ref} onClick={onClick} isSelected={isSelected}>
			{item}
		</ButtonItem>
	);
}
