import React from 'react';

import { useAISmartButton } from '@atlassian/ai-smart-button-api';

import { Greeting } from './greeting';
import { Reference } from './reference';

export function HeadingSection({ name }: { name: string }) {
	const [{ context }] = useAISmartButton();

	return context ? <Reference /> : <Greeting name={name} />;
}
