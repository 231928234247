import { createContext, useContext, useEffect, useState } from 'react';

import memoizeOne from 'memoize-one';

import { me } from '../../services/me';

import { type ProfileData } from './types';

const fetchProfileData = memoizeOne(async (): Promise<ProfileData> => {
	const data = await me();

	return {
		aaid: data.account_id,
		name: data.name || '',
		displayName: data.nickname,
		avatarUrl: data.picture,
		email: data.email,
	};
});

export const ProfileContext = createContext<ProfileData | null>(null);

const emptyProfileData: ProfileData = {
	name: '',
	displayName: '',
	avatarUrl: '',
};

export const useProfileController = (): ProfileData => {
	const [profileData, setProfileData] = useState<ProfileData>(emptyProfileData);
	const profileContextData = useContext(ProfileContext);

	useEffect(() => {
		if (profileContextData) {
			setProfileData(profileContextData);
		} else {
			fetchProfileData()
				.then((data) => setProfileData(data))
				.catch(() => setProfileData(emptyProfileData));
		}
	}, [setProfileData, profileContextData]);

	return profileData;
};
