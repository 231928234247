import { performGqlFetch } from '@atlassian/conversation-assistant-service';

import { type CloudIdResponse, type CloudIdVariables } from './types';

const CloudIdLookupQuery = `
query tenantQuery($cloudIds: [ID!]) {
  tenantContexts(cloudIds: $cloudIds) {
    cloudUrl
	orgId
  }
}
`;

export const getCloudUrlFromId = async (cloudId: string) => {
	const response = await performGqlFetch<CloudIdResponse, CloudIdVariables>({
		gqlQuery: CloudIdLookupQuery,
		variables: {
			cloudIds: [cloudId],
		},
	});
	return response.data?.tenantContexts?.[0];
};
