import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import type { ApolloError } from 'apollo-client';
import type { GraphQLError } from 'graphql';

import { markErrorAsHandled } from '@confluence/graphql';
import { isStatusCodeError } from '@confluence/error-types';

import { NO_CLASSIFICATION } from '../constants/NoClassification';
import { DefaultAndSpaceClassificationLevelsQuery } from '../queries/DefaultAndSpaceClassificationQuery.graphql';
import type { ClassificationLevel } from '../ClassificationRadioOption';
import { Status } from '../constants/Status';
import type {
	DefaultAndSpaceClassificationLevelsQuery as DefaultAndSpaceClassificationLevelsQueryType,
	DefaultAndSpaceClassificationLevelsQueryVariables,
} from '../queries/__types__/DefaultAndSpaceClassificationLevelsQuery';

import { useContentClassification } from './useContentClassification';
import { useOrgDefaultClassificationLevelId } from './useOrgDefaultClassificationLevelId';

export const useClassificationChangeState = (
	contentId: string,
	selectedSpaceKey?: string,
	currentSpaceKey?: string | null,
) => {
	const [unhandledError, setUnhandledError] = useState<Error>();
	const [selectedSpaceClassification, setSelectedSpaceClassification] =
		useState<ClassificationLevel | null>(null);

	// Mark 404 errors, when space data classification isn't assigned, as handled since it's expected
	// Set unhandled error for all other errors
	const processError = useCallback((error: ApolloError | GraphQLError) => {
		if (isStatusCodeError(error, '404')) {
			markErrorAsHandled(error);
		} else {
			setUnhandledError(error);
		}
	}, []);

	/*
    Fetch classification for current page
    Currently no error handling for content classification since the behavior is to
    ignore content classification and use space default classification if there's an error.

    If intended behavior changes in the future, we'll need to handle the error.
  */
	const {
		classification: currentClassification,
		contentLevelLoading: currentClassificationLoading,
	} = useContentClassification({
		contentId,
		spaceKeyOverride: currentSpaceKey,
	});

	// fetch classification for target space
	// default target space is current space
	const {
		data: selectedSpaceData,
		loading: selectedSpaceLoading,
		error: selectedSpaceError,
		refetch: selectedSpaceRefetch,
	} = useQuery<
		DefaultAndSpaceClassificationLevelsQueryType,
		DefaultAndSpaceClassificationLevelsQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		DefaultAndSpaceClassificationLevelsQuery,
		{
			variables: { spaceKey: currentSpaceKey || '' },
			skip: !currentSpaceKey,
			errorPolicy: 'all',
			fetchPolicy: 'network-only',
			notifyOnNetworkStatusChange: true,
		},
	);

	const {
		orgDefaultClassificationLevelId,
		orgDefaultClasificationLoading,
		orgDefaultClassificationError,
	} = useOrgDefaultClassificationLevelId({ skip: !currentSpaceKey });

	// if selected space changes, load/refetch query
	useEffect(() => {
		if (!selectedSpaceKey) return;

		// reset unhandled error (so if one exists, it'll cleared when the selected space changes)
		setUnhandledError(undefined);

		// reset selected space classification when selected space changes
		// causes additional rerender but necessary to avoid displaying wrong classification for space
		setSelectedSpaceClassification(null);
		selectedSpaceRefetch({ spaceKey: selectedSpaceKey }).catch(processError);
	}, [selectedSpaceKey, selectedSpaceRefetch, processError]);

	// handle selected space data updates
	useEffect(() => {
		if (selectedSpaceLoading || orgDefaultClasificationLoading) {
			return;
		}

		if (selectedSpaceError) {
			processError(selectedSpaceError);
			if (!orgDefaultClassificationLevelId) return;
		}

		if (orgDefaultClassificationError) {
			processError(orgDefaultClassificationError);
			return;
		}

		const selectedSpaceClassificationId =
			selectedSpaceData?.space?.defaultClassificationLevelId ?? orgDefaultClassificationLevelId;
		const selectedSpaceClassificationLevels = selectedSpaceData?.classificationLevels || [];

		const selectedSpaceClassification =
			selectedSpaceClassificationLevels.find(
				(level) => level?.id === selectedSpaceClassificationId,
			) || null;

		// type error from converting `color: string` to `color: Color`, so safe to cast
		setSelectedSpaceClassification(selectedSpaceClassification as ClassificationLevel);
	}, [
		selectedSpaceData,
		selectedSpaceLoading,
		selectedSpaceError,
		processError,
		orgDefaultClassificationLevelId,
		orgDefaultClasificationLoading,
		orgDefaultClassificationError,
	]);

	// retrieve classification name of selected space. Otherwise it's NO_CLASSIFICATION
	const selectedSpaceClassificationName =
		selectedSpaceClassification?.status === Status.PUBLISHED
			? selectedSpaceClassification?.name || NO_CLASSIFICATION
			: NO_CLASSIFICATION;
	// retrieve classification name of current space. Otherwise it's NO_CLASSIFICATION
	const currentClassificationName =
		currentClassification?.status === Status.PUBLISHED
			? currentClassification?.name || NO_CLASSIFICATION
			: NO_CLASSIFICATION;

	/*
	 * evaluates if classification changed when new space was selected
	 * Changed if selected space is different from current space
	 * AND
	 * if published classification id is different from current published classification id
	 * if classification isn't published (e.g. archived/deleted), then it's considered as NO_CLASSIFICATION
	 *
	 * so if selected space classification is archived, and current classification is also archived, it's not considered as changed
	 */
	const classificationChanged =
		!!selectedSpaceKey &&
		selectedSpaceKey !== currentSpaceKey &&
		(selectedSpaceClassification?.status === Status.PUBLISHED ||
			currentClassification?.status === Status.PUBLISHED) &&
		selectedSpaceClassification?.id !== currentClassification?.id;

	return {
		classificationChanged,
		selectedClassification: selectedSpaceClassificationName,
		currentClassification: currentClassificationName,
		classificationLoading:
			selectedSpaceLoading || currentClassificationLoading || orgDefaultClasificationLoading,
		classificationError: unhandledError,
	};
};
