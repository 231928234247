import React from 'react';

import { useObjectSidebar } from '@confluence/object-sidebar-api';

export function useCreateObjectSidebarProp() {
	const [
		{ isObjectSidebarShown, panel },
		{ changeHeaderElements, showObjectSidebar, hideObjectSidebar, hideObjectSidebarByPanelId },
	] = useObjectSidebar();

	/**
	 * DON'T REMOVE useMemo here, otherwise it will cause very very serious performance issue
	 */
	return React.useMemo(() => {
		return {
			state: { isObjectSidebarShown, panel },
			actions: {
				changeHeaderElements,
				showObjectSidebar,
				hideObjectSidebar,
				hideObjectSidebarByPanelId,
			},
		};
	}, [
		isObjectSidebarShown,
		panel,
		changeHeaderElements,
		showObjectSidebar,
		hideObjectSidebar,
		hideObjectSidebarByPanelId,
	]);
}
