import React, { createContext, useMemo, useState } from 'react';

import { AIAction } from '@atlassian/ai-smart-button-api';

type AIDynamicSurfaceCommonData = {
	activeAction?: AIAction;
	setActiveAction: (action: AIAction | undefined) => void;
};

export const AIDynamicSurfaceContext = createContext<AIDynamicSurfaceCommonData>({
	setActiveAction: (action: AIAction | undefined) => {},
});

export const AIDynamicSurfaceContextProvider = ({ children }: { children: React.ReactElement }) => {
	const [activeAction, setActiveAction] = useState<AIAction | undefined>();

	const contextValue = useMemo(
		() => ({ activeAction, setActiveAction }),
		[activeAction, setActiveAction],
	);

	return (
		<AIDynamicSurfaceContext.Provider value={contextValue}>
			{children}
		</AIDynamicSurfaceContext.Provider>
	);
};
