import { addRovoParamsToUrl } from '@atlaskit/rovo-triggers';

const ATLAS_PROD_URL = 'https://home.atlassian.com';
const ATLAS_STAGING_URL = 'https://home.stg.atlassian.com';

export const getAtlasHost = (): string => {
	const host = window.location.host;
	const isStaging =
		host.includes('localhost') ||
		host.includes('.stg.atlassian') ||
		host.includes('.stg-east.frontend.public.atl-paas.net') ||
		host.includes('jira-dev.com');

	return isStaging ? ATLAS_STAGING_URL : ATLAS_PROD_URL;
};

// This is exported as public API for usage by other packages
export const getAtlasURL = (siteId: string, path: string, orgId: string | undefined): string => {
	let argConcat = '?';
	let processedPath = path.startsWith('/') ? path : `/${path}`;

	if (processedPath.includes('?')) {
		argConcat = '&';
	}

	const basePath = orgId ? `${getAtlasHost()}/o/${orgId}` : getAtlasHost();

	return addRovoParamsToUrl(`${basePath}${processedPath}${argConcat}cloudId=${siteId}`, {
		cloudId: siteId,
	});
};
